$(window).load(function(){
});
$(function() {

    //アコーディオンメニューの実装
    $window = $(window);
    $(".toggle_content").hide();
    $(".toggle_trigger").on('click', function () {
        var $thisElm = $(this),
            $activeElm = $(".toggle_trigger.active"),
            currentScrollTop = $window.scrollTop(),
            posTop = $thisElm.offset().top,
            currentPosTop = posTop;
        if(!($thisElm.hasClass("active"))) {
          $activeElm.removeClass("active");
          $activeElm.next(".toggle_content").slideUp();
          $thisElm.addClass("active");
          $thisElm.next(".toggle_content").slideDown({
            step: function() { //各ステップごとに、移動分スクロールさせる
              currentPosTop = $thisElm.offset().top;
              $("html,body").animate({
                "scrollTop": currentScrollTop - (posTop - currentPosTop) //スクロールを実行
              }, 0);
            }
          });
        } else {
          $activeElm.removeClass("active");
          $activeElm.next(".toggle_content").slideUp();
          $thisElm.next(".toggle_content").slideUp({
            step: function() { //各ステップごとに、移動分スクロールさせる
              currentPosTop = $thisElm.offset().top;
              $("html,body").animate({
                "scrollTop": currentScrollTop - (posTop - currentPosTop) //スクロールを実行
              }, 0);
            }
          });
        }
    });

});