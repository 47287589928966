//幅取得
var scaleWindowW = function() {
    var w = (window.innerWidth || document.documentElement.clientWidth || 0);
    return w;
};

//スライドショー
window.addEventListener('DOMContentLoaded', function() {
    var mySwiper = undefined;
    var swiperEl = document.querySelector('.visual_img.swiper-container');
    var swiperWrapper = document.getElementsByClassName('.visual_img swiper-wrapper');
    var swiperSlide = document.getElementsByClassName('.visual_img swiper-slide');
    var initSwiper = function() {
    if (scaleWindowW() < 768 && mySwiper == undefined) {
        mySwiper = new Swiper(swiperEl, {
            // slidesPerView: 'auto',
            // spaceBetween: 20,
            // centeredSlides : true,
            loop: true,
            effect: "fade",
            autoplay: {
                delay: 3000,
            },
            speed: 2000
            // pagination: {
            //     el: '.swiper-pagination',
            // }
        });
    } else if (scaleWindowW() >= 768 && mySwiper != undefined) {
        mySwiper.destroy();
        mySwiper = undefined;
        for ( var i = 0; i < swiperWrapper.length; i++ ) {
            swiperWrapper[i].removeAttribute('style');
        }
        for ( var i =0; i < swiperSlide.length; i++ ) {
            swiperSlide[i].removeAttribute('style');
        }
    }
    }
    initSwiper();
    window.addEventListener('resize',initSwiper);
}, false);

$(window).on("load",function(){
});

$(function(){

    var checkWidth = function() {
        var browserWidth = $(window).width();
        if(browserWidth <= 767){
            // スマホサイズ
        } else {
            // PCサイズ
            $('.visual_img .swiper-slide').addClass('animated fadeInRight');
        }
    };
    $(function(){
        checkWidth();
        $(window).resize(checkWidth);
    });

});